body {

  background-color: #212529;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Julius Sans One';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
img {
  max-width: 100%;
  max-height: auto;
}

.col {
	display: flex;
	justify-content: center;
}
.filters{
	position:-webkit-sticky;
	position:sticky;
	top: 100pt;
	height:95vh;
	margin-top:15pt;
}
.filters-sm{
	position: -webkit-sticky;
	position: sticky;
	background-color: #212529;
	padding: 0 10pt 0 10pt;
	z-index: 9999;
	top: 40pt;
	width:100%;
}
.filters-sm ul{
	justify-content: space-between;
}
.nav-item h3{
	cursor: pointer;
}
.filters-sm h3{
	font-size:1.4rem;
}
h3{
	font-size:1.70rem;
	color: #9c9c9c;
}

.filters ul li h3{
	margin:30px 0px;
}
.active h3{
	color: #e2e4eb 
}
ul{
	list-style-type: none;
	padding:00;
}
@media screen and (min-width: 576px) {
	.filters-sm ul{
		justify-content: space-around;
	}

}
.my-gallery-class {
}
.gallery-children{
	padding-bottom: 30px;
	z-index: 0;
}
.title-overlay:hover {
	opacity: 0.75;
	background-color: black;
	background-clip: content-box;
	cursor: pointer;
}
.title-overlay {
	position:absolute;
	width: 100%;
	height: 100%;
	overflow: hidden;
	padding:0px 0px 0px 0px;
	margin: -0px -0px -0px -0px;
	display:flex;
	align-items: center;
	justify-content: center;
	transition: 0.5s ease;
}
h4{
	display:none;
	margin:0;
}
.gallery-element{
	padding: 0;
	position: relative;
	overflow: hidden;
}
.title-overlay:hover h4{
	display: inline;
}
#lightboxBackdrop{
	z-index: 9999;
	width: 100vw;
	height:100vh;
}
.prof{
	height:40vh;
	display: flex;
	justify-content: space-between;
	padding: 30px 0 30px 30px;
}
.prof .body{
	width:100%;
	display: flex;
	justify-content: center;
}
.prof-pic{
  border-radius: 50%;
  width: auto;
  height: 100%;

}
#header{
	background-color: #212529;
	z-index: 9999;
	height:100pt;
	padding-left: 20pt;

}
.affix{
}
h1{
	font-size:3.4rem;
}
@media screen and (max-width: 768px) {
	.navbar{
		height: 40pt !important;
		justify-content: center;
	}
	.navbar h1{
		font-size:2rem;
	}

}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.container-fluid{
  margin:0;
  padding:0;}
#photos{
  background-color: 
}
@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

